import React, { useEffect, useState } from "react";
import style from "./about.module.css";
const About = (props) => {
  return (
    <>
      <div className={style.about_section}>About Us</div>
    </>
  );
};

export default About;
