import React, { useEffect, useRef, useState } from "react";
import style from "./searchSelect.module.css";
import Loader from "./loader";
import { CheckCircle } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SearchSelect = (props) => {
  const [selectOption, setSelectionOption] = useState([]);
  const ref = useRef();
  const [selected, setSelected] = useState({});
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [sendSelected, setSendSelected] = useState();
  const [randomID] = useState("id_" + Math.floor(Math.random() * 1000000 + 1));

  const onSelect = (item) => {
    setSelected({ ...item });
    setSendSelected({ ...item });
    setOpen((prev) => !prev);
  };

  const dataFilter = (data) => {
    if (data.length > 0 && data[0].title)
      return data.filter((item) => {
        return item.title.toLowerCase().includes(search.toLowerCase());
      });
    else return [];
  };

  useEffect(() => {
    console.log("sdfsf", props.setValue);
    props.setValue
      ? setSelected(() => {
          let selectedValue = {};
          selectOption.map((item) => {
            if (
              item.value &&
              props.setValue.toString().toLowerCase() ===
                item.value.toString().toLowerCase()
            ) {
              selectedValue = item;
            }
          });
          return selectedValue;
        })
      : setSelected({});
    console.log(selected);
  }, [props.setValue, selectOption]);

  useEffect(() => {
    props.setOption && setSelectionOption(props.setOption);
  }, [props.setOption]);

  useEffect(() => {
    if (sendSelected) props.getValue && props.getValue(sendSelected);
  }, [sendSelected]);

  useEffect(() => {
    const clickListener = (e) => {
      if (
        document.getElementsByClassName(randomID) && ref && ref.current && 
        !ref.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    window.addEventListener("click", clickListener);

    return () => {
      window.removeEventListener("click", clickListener);
    };
  }, []);

  return (
    <React.Fragment>
      <div
        className={`${style.search_select} ${
          props.customCss && props.customCss
        } ${props.disabled ? style.disabled : ""}`}
        id={randomID}
        ref={ref}
      >
        {props.label && (
          <label htmlFor={props.id}>
            {props.icon && <FontAwesomeIcon icon={props.icon} />}
            {props.label} {props.required === true && <sup>*</sup>}
          </label>
        )}
        <div className={style.search_area}>
          <div
            className={`${style.search_btn} ${
              props.customCssForBtn ? props.customCssForBtn : ""
            } ${props.error ? style.error : ""} capitalize`}
            onClick={() => {
              if (!props.disabled) setOpen((prev) => !prev);
            }}
          >
            {selected.title || props.placeholder || "Select Menu"}
            {props.loader && <Loader className={style.loader} />}
          </div>
          {open && (
            <ul className={style.select_list}>
              <div className={style.select_search}>
                <input
                  type="text"
                  placeholder="Search Here"
                  value={search}
                  onInput={(e) => setSearch(e.target.value)}
                />
              </div>
              {selectOption.length > 0 &&
                dataFilter(selectOption).map((item, index) => (
                  <li
                    className={`${
                      selected.value === item.value && style.selected
                    } capitalize`}
                    onClick={() => onSelect(item)}
                    key={index}
                  >
                    {selected.title === item.title &&
                      selected.value === item.value && <CheckCircle />}
                    {item && item.title}
                  </li>
                ))}
              {props.loader && (
                <div className={style.noData}>
                  <Loader className={style.loader} />
                </div>
              )}
              {!props.loader && dataFilter(selectOption).length === 0 && (
                <div className={style.noData}>No data available</div>
              )}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default SearchSelect;
