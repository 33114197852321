import Header from "./layout/header/header";
import Main from "./layout/main/main";
import Sidebar from "./layout/sidebar/sidebar";
import style from "./root.module.css";
import { Outlet } from "react-router-dom";
import api, { authApi } from "./scripts/api";
import { useNavigate } from "react-router-dom";
import { Suspense, useEffect, useMemo } from "react";
import { useLoaderData } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import {
  loadNotificationCount,
  loadNotificationCountWithoutReload,
  loadData,
  loadResellerNotificationCount,
  loadResellerNotificationCountWithoutReload,
} from "./store";
import { socket } from "./scripts/socket";
import { useDispatch, useSelector } from "react-redux";
import { authStoreActions } from "./store/auth";
import { Await } from "react-router-dom";
import Loader from "./components/utils/loader";
import { generalStoreActions } from "./store/general";
import './scripts/Tour.css';

function Root(props) {
  const role = useSelector((state) => state.authStore.role);
  const loaderData = useLoaderData();
  console.log("loaderDatat: ", loaderData);
  const dispatch = useDispatch();

  useMemo(() => {
    console.log("loaderData", loaderData);
    console.log({ onboard: loaderData.onboard ? true : false })
    if (loaderData) {
      if (loaderData.company) {

        dispatch(
          authStoreActions.updateAuthData({
            name: loaderData.name,
            role: loaderData.role,
            company_id: loaderData.company._id,
            company_name: loaderData.company.name,
            onboard: loaderData.onboard ? true : false
          })
        );
      }
      if (loaderData.role === "reseller") {
        loadResellerNotificationCount();
        loadData("reseller");
        socket.on(
          `${process.env.REACT_APP_PRODUCT_ID}_${loaderData.company._id}_reload_notification`,
          (message) => {
            loadResellerNotificationCountWithoutReload();
          }
        );
      } else if (loaderData.role === "admin") {

        loadNotificationCount();
        loadData();
        socket.on(
          `${process.env.REACT_APP_PRODUCT_ID}_${loaderData.company._id}_reload_notification`,
          (message) => {
            loadNotificationCountWithoutReload();
          }
        );
      }
      return () => {
        socket.off(
          `Disconnected ${process.env.REACT_APP_PRODUCT_ID}_${loaderData.company._id}_reload_notification`
        );
      };
    }
  }, []);

  // Checking if navigator
  useEffect(() => {
    api.interceptors.response.use(
      (res) => res,
      function (error) {
        if (error.response.status === 401) {
          if (role === "reseller") {
            window.location.href = "/reseller/login";
          } else window.location.href = "/admin/login";
        }
        return Promise.reject(error);
      }
    );
    authApi.interceptors.response.use(
      (res) => res,
      function (error) {
        if (error.response.status === 401) {
          if (role === "reseller") {
            window.location.href = "/reseller/login";
          } else window.location.href = "/admin/login";
        }
        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <>
      <Suspense fallback={<RouteMainLoader />}>
        <Await
          resolve={loaderData}
          errorElement={
            <p>Error in loading application, Contact Administrator</p>
          }
        >
          <div className={style.grid_template}>
            <div className={style.grid_element_sidebar}
            >
              <Sidebar loaderData={loaderData} />
            </div>
            <div className={style.grid_element_main}>
              <Header loaderData={loaderData} />
              <div className={style.body_section}>
                <Main>
                  <Outlet />
                </Main>
              </div>
            </div>
          </div>
          <NotificationContainer />
        </Await>
      </Suspense>
    </>
  );
}

export default Root;

export const RouteMainLoader = () => {
  return (
    <div className={style.main_section}>
      <div className={style.head_section}>
        <h1>Ecommerce Manager</h1>
        <p>
          powered by <span>K</span>notHost
        </p>
        <Loader className={style.loader} />
      </div>
    </div>
  );
};

export const RouteNavLoader = () => {
  return (
    <div className={style.main_section_nav}>
      <div className={style.head_section_nav}>
        <h1>Ecommerce Manager</h1>
        <p>
          powered by <span>K</span>notHost
        </p>
        <Loader className={style.loader} />
      </div>
    </div>
  );
};
