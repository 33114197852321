import React, { useEffect, useMemo, useState } from "react";
import style from "./profile.module.css";
import InputText from "../utils/inputText";
import api from "../../scripts/api";
import { createNotification } from "../../customHooks/useNotification";
import Loader from "../utils/loader";
import { useDispatch, useSelector } from "react-redux";
import BtnLoader from "../utils/btnLoader";
import { authStoreActions } from "../../store/auth";
import ResellerResetPassword from "./resetPassword/resellerResetPassword";
import SearchSelect from "../utils/searchSelect";

const ResellerProfile = (props) => {
  const [loader, setLoader] = useState(true);
  const [userData, setUserData] = useState({});
  const [countriesOption, setCountryOption] = useState([]);
  const [data, setData] = useState({
    name: "",
    country: "",
    phone: "",
    whatsapp_number: "",
  });
  const [btnLoader, setBtnLoader] = useState(false);
  const [error, setError] = useState({ name: "", phone: "", country: "" });
  const dispatch = useDispatch();
  const [resetBoxOpen, setResetBoxOpen] = useState(false);

  const companyID = useSelector((state) => state.authStore.company_id);
  useEffect(() => {
    loadCountryData();
    onLoadData();
  }, []);

  const [countryLoader, setCountryLoader] = useState(true);

  const loadCountryData = async (searchValue = "") => {
    setCountryLoader(true);
    await api
      .get("/reseller/country")
      .then((res) => {
        setCountryOption(() => {
          let newArray = [];
          if (res.data.data.length > 0)
            for (const item of res.data.data) {
              newArray.push({
                title: item.name,
                value: item.name.toLowerCase(),
              });
            }
          return newArray;
        });
        setCountryLoader(false);
      })
      .catch((err) => {
        setCountryLoader(false);
        console.log(err);
      });
  };

  const onLoadData = async () => {
    try {
      setLoader(true);
      await api
        .get("/reseller")
        .then(async (res) => {
          setLoader(false);
          if (res.data && res.data.data && res.data.success === true) {
            const loadedData = res.data.data;
            setUserData(res.data.data);
            setData({
              name: loadedData.name,
              country: loadedData.country,
              phone: loadedData.phone,
              whatsapp_number: loadedData.whatsapp_number,
            });
          }
        })
        .catch((err) => {
          createNotification("error", "Something Went Wrong");
          setLoader(false);
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const onSaveData = async () => {
    setError({ name: "", phone: "", country: "" });
    let errorFound = false;
    if (!data.name) {
      setError((prev) => {
        let newObject = { ...prev };
        newObject.name = "Name is empty";
        return newObject;
      });
      errorFound = true;
    }
    if (!data.country) {
      setError((prev) => {
        let newObject = { ...prev };
        newObject.country = "Country is empty";
        return newObject;
      });
      errorFound = true;
    }
    if (!data.phone) {
      setError((prev) => {
        let newObject = { ...prev };
        newObject.phone = "Phone Number is empty";
        return newObject;
      });
      errorFound = true;
    }
    if (errorFound === false) {
      try {
        setBtnLoader(true);
        await api
          .post("/reseller", data)
          .then(async (res) => {
            setBtnLoader(false);
            dispatch(authStoreActions.updateAuthData({ name: data.name }));
            createNotification("success", "Updated Successfully");
          })
          .catch((err) => {
            createNotification("error", "Something Went Wrong");
            setBtnLoader(false);
            console.log(err);
          });
      } catch (err) {
        console.log(err);
      }
    }
  };
  return (
    <>
      <div className={style.profile_section}>
        <h1 className={style.heading}>Profile</h1>
        {!loader ? (
          <div className={style.body}>
            <div className={style.section}>
              <div className={style.content}>
                <div className={style.grid}>
                  <InputText
                    label="User ID"
                    value={userData._id}
                    disabled={true}
                  />
                  <InputText
                    label="Company ID"
                    value={companyID}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
            <div className={style.section}>
              <p>Account</p>
              <hr />
              <div className={style.content}>
                <div className={style.grid}>
                  <InputText
                    label="Name"
                    getValue={(value) =>
                      setData((prev) => {
                        let newObject = { ...prev };
                        newObject.name = value;
                        return newObject;
                      })
                    }
                    value={data.name}
                    required={true}
                    error={error.name}
                  />
                  <SearchSelect
                    setOption={countriesOption}
                    placeholder={`Select Country`}
                    label="Country"
                    loader={countryLoader}
                    error={error["country"]}
                    setValue={data.country}
                    getValue={(value) => {
                      setData((prev) => {
                        let newObject = { ...prev };
                        newObject.country = value.value;
                        return newObject;
                      });
                      if (value.value) {
                        setError((prev) => {
                          let newArray = { ...prev };
                          newArray["country"] = null;
                          return newArray;
                        });
                      }
                    }}
                    customCssForBtn={style.customCountrySelectCss}
                  />
                  <InputText
                    type="tel"
                    label="Phone Number"
                    maxLength={13}
                    minLength={10}
                    error={error["phone"]}
                    placeholder="Enter Phone No"
                    value={data.phone}
                    getValue={(value) => {
                      setData((prev) => {
                        let newArray = { ...prev };
                        newArray.phone = value;
                        return newArray;
                      });
                    }}
                    required={true}
                  />
                  <InputText
                    type="tel"
                    label="Whatsapp Number"
                    maxLength={13}
                    minLength={10}
                    error={error["whatsapp_number"]}
                    placeholder="Enter Whatsapp No"
                    value={data.whatsapp_number}
                    getValue={(value) => {
                      setData((prev) => {
                        let newArray = { ...prev };
                        newArray.whatsapp_number = value;
                        return newArray;
                      });
                    }}
                  />
                  <InputText
                    label="Email"
                    value={userData.email}
                    disabled={true}
                  />
                </div>
                {!btnLoader ? (
                  <button
                    type="button"
                    className={`primary-btn ${style.updateBtn} mt-4`}
                    onClick={() => onSaveData()}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    className={`primary-btn ${style.updateBtn} mt-4`}
                    type="button"
                  >
                    <BtnLoader className={style.btnLoader} />
                  </button>
                )}
              </div>
            </div>
            <div className={style.section}>
              <p>Reset Password</p>
              <hr />
              <div className={style.btn_section}>
                <button
                  className={`secondary-btn ${style.resetBtn}`}
                  type="button"
                  onClick={() => setResetBoxOpen(true)}
                >
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        ) : (
          <Loader className={style.loader} />
        )}
      </div>
      <ResellerResetPassword
        onOpen={resetBoxOpen}
        onClose={() => setResetBoxOpen(false)}
      />
    </>
  );
};

export default ResellerProfile;
