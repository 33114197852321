import React, { useEffect, useState } from "react";
import style from "./help.module.css";
const Help = () => {
  return (
    <>
      <div className={style.help_section}>
        <h1 className={style.heading}>Help</h1>
        <div className={style.body}>
          <div className={style.section}>
            <p>Quick Links</p>
            <hr />
            <div className={style.content}>
              <li>
                <a
                  href="https://docs.knothost.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  Documentation
                </a>
              </li>
              <li>
                <a
                  href="https://www.knothost.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  KnotHost Website
                </a>
              </li>
            </div>
          </div>
          <div className={style.section}>
            <p>Contact</p>
            <hr />
            <div className={style.content}>
              <li>
                <span>Email : </span>{" "}
                <a
                  href="mailto:ec-manager@knothost.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  ec-manager@knothost.com
                </a>
              </li>
              <li>
                <span>Phone : </span>{" "}
                <a href="tel:+971589481195" target="_blank" rel="noreferrer">
                  +971 58 948 1195
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
