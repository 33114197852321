import "./App.css";
import { RouterProvider } from "react-router-dom";
import routes from "./routes/routes";
import { useEffect } from "react";
import { socket } from "./scripts/socket";

const App = () => {
  useEffect(() => {}, []);
  return <RouterProvider router={routes} />;
};

export default App;
