import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loader: true,
  count: null,
};
const notificationCountStore = createSlice({
  name: "notificationCountStoreAction",
  initialState: initialState,
  reducers: {
    updateCount(state, action) {
      state.count = action.payload.count;
    },
    setLoader(state, action) {
      state.loader = action.payload.loader;
    },
    clearAuthData(state) {
      state.count = null;
    },
  },
});

export const notificationCountStoreActions = notificationCountStore.actions;

export default notificationCountStore;
