import React, { useEffect, useMemo, useState } from "react";
import style from "./lightDarkToggleSwitch.module.css";
import { IoMoonOutline } from "react-icons/io5";
import { IoSunnyOutline } from "react-icons/io5";
import { useSelector, useDispatch } from "react-redux";
import { generalStoreActions } from "../../store/general";

const LightDarkToggleSwitch = (props) => {
  const themeMode = useSelector((state) => state.generalStore.theme_mode);
  const dispatch = useDispatch();

  const value = { dark: "light", light: "dark" };
  return (
    <div
      className={`${style.toggle_section} ${
        props.customClass ? props.customClass : ""
      }`}
    >
      <div
        className={style.toggle_flex}
        onClick={() => {
          localStorage.setItem("theme_mode", value[themeMode]);
          dispatch(
            generalStoreActions.updateThemeMode({
              theme_mode: value[themeMode],
            })
          );
        }}
      >
        <div
          className={`${style.dark} ${
            themeMode === "dark" ? style.active : ""
          }`}
        >
          <IoMoonOutline />
        </div>
        <div
          className={`${style.light} ${
            themeMode === "light" ? style.active : ""
          }`}
        >
          <IoSunnyOutline />
        </div>
        <div
          className={`${style.toggle} ${
            themeMode === "dark" ? style.left : ""
          }`}
        ></div>
      </div>
    </div>
  );
};
export default LightDarkToggleSwitch;
