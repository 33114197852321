import React, { useState, useEffect } from "react";
import style from "./overlayBox.module.css";

const OverlayBox = (props) => {
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);
  const onClickClose = () => {
    setOpen((prev) => !prev);
    if (props.close) props.close(false);
  };
  return (
    <React.Fragment>
      {open && (
        <React.Fragment>
          <div className={style.overlay_blur} />
          <div
            className={`${style.overlay_box} ${
              props.customClass && props.customClass
            }`}
          >
            <div className={style.overlay_content}>
              <div className={style.overlay_head}>
                <h6>{props.title}</h6>
                <div className={style.close} onClick={onClickClose}>
                  &#10006;
                </div>
              </div>
              <div
                className={`${style.overlay_body} ${
                  props.customBodyClass ? props.customBodyClass : ""
                }`}
              >
                {props.children}
              </div>
              {props.footer && (
                <div className={style.overlay_footer}>{props.footer}</div>
              )}
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default OverlayBox;
