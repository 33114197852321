import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.min.css";
import "@popperjs/core";
import "bootstrap";
import "@fortawesome/react-fontawesome";
import "./publicCSS/style.css"
import "dropify/dist/js/dropify";
import "dropify/dist/css/dropify.css";
import store from "./store/index";
import { Provider } from "react-redux";
import SetThemeType from './components/others/setThemeType';

// if (process.env.REACT_APP_NODE_ENV === 'production') {
//   console.log = () => { }
//   console.error = () => { }
//   console.debug = () => { }
// }

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <SetThemeType />
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
